<div
  id="wp-time-entry-info"
  *ngIf="isShown"
  name="area"
  class="popover-box"
  [formGroup]="form"
  [ngStyle]="containerStyles"
  (clickOutside)="service.close()"
  [delayClickOutsideInit]="true"
  exclude="[wpTimesheetCell]"
>
  <div style="margin-bottom: 7px; position: relative">
    <textarea
      autosize
      [minRows]="4"
      [maxRows]="10"
      rows="4"
      placeholder="{{ 'timesheets.card.entryInfo.comment' | translate }}"
      class="form-control"
      formControlName="comments"
    ></textarea>
  </div>

  <div
    *ngIf="!readonly"
    class="btn-group btn-group-sm btn-group-justified"
    role="group"
    style="margin-bottom: 3px"
  >
    <div class="btn-group btn-group-sm" role="group">
      <button
        style="padding-left: 2px; padding-right: 2px"
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[0] }}"
        (click)="setDuration(1)"
      >
        {{ buttonTitles[0] }}
      </button>
    </div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        style="padding-left: 2px; padding-right: 2px"
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[1] }}"
        (click)="setDuration(2)"
      >
        {{ buttonTitles[1] }}
      </button>
    </div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[2] }}"
        (click)="setDuration(3)"
      >
        {{ buttonTitles[2] }}
      </button>
    </div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[3] }}"
        (click)="setDuration(4)"
      >
        {{ buttonTitles[3] }}
      </button>
    </div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[4] }}"
        (click)="setDuration(5)"
      >
        {{ buttonTitles[4] }}
      </button>
    </div>

    <div class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        class="btn btn-default"
        title="{{ buttonHints[5] }}"
        (click)="setDuration(6)"
      >
        {{ buttonTitles[5] }}
      </button>
    </div>

    <div *ngIf="showStartButton" class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        [disabled]="swExecuted"
        class="btn btn-default"
        title="{{ 'stopwatch.actions.start.hint' | translate }}"
        (click)="start()"
        data-test="startStopwatch"
      >
        <span class="bi bi-play-fill"></span>
      </button>
    </div>
    <div *ngIf="showStopButton" class="btn-group btn-group-sm" role="group">
      <button
        type="button"
        id="action-stop-stopwatch"
        [disabled]="swExecuted"
        class="btn btn-default"
        title="{{ 'stopwatch.actions.stop.hint' | translate }}"
        (click)="stop('Increment')"
      >
        <span class="bi bi-stop-fill" [ngClass]="{ gray: isPause, red: !isPause }"></span>
      </button>
    </div>
  </div>
  <wp-custom-fields
    [formGroup]="form"
    [fields]="timesheetCardService.allocationCustomFields"
    columnClasses="col-12"
    [entityType]="'AllocationInfo'"
  >
  </wp-custom-fields>
</div>
