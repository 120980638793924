/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef,
  Renderer2,
  inject,
  DestroyRef,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  signal,
  WritableSignal,
  effect,
  OnChanges,
  SimpleChanges,
  Injector,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Node, Schema } from 'prosemirror-model';
import { schema as basicSchema } from 'prosemirror-schema-basic';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { undo, redo, history } from 'prosemirror-history';
import { baseKeymap } from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';
import autocomplete, {
  KEEP_OPEN,
  AutocompleteAction,
  ActionKind,
  FromTo,
  closeAutocomplete,
} from 'prosemirror-autocomplete';
import {
  MarkdownParser,
  defaultMarkdownParser,
  defaultMarkdownSerializer,
} from 'prosemirror-markdown';

import {
  BehaviorSubject,
  Subject,
  debounceTime,
  fromEvent,
  switchMap,
  tap,
} from 'rxjs';
import _ from 'lodash';
import { LinkyPipe } from 'ngx-linky';

import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  UserInfoComponentParams,
  UserInfoComponent,
} from 'src/app/shared/components/features/user-info';

import { md } from './markdown';
import {
  RichEditorBoxMenuService,
  RichEditorBoxMenuComponent,
  tagMark,
  additionalMarks,
  markHotKey,
} from './rich-editor-box-menu';

@Component({
  selector: 'tmt-rich-editor-box',
  templateUrl: './rich-editor-box.component.html',
  styleUrls: ['./rich-editor-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorBoxComponent),
      multi: true,
    },
    RichEditorBoxMenuService,
  ],
  standalone: true,
  imports: [CommonModule, SharedModule, RichEditorBoxMenuComponent],
})
export class RichEditorBoxComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('popup') private suggestionsEl: TemplateRef<HTMLElement>;
  @ViewChild(RichEditorBoxMenuComponent)
  private menuBar: RichEditorBoxMenuComponent;

  @Input() public placeholder = 'shared.comments.placeholder';
  @Input() public loadLimit = 50;
  @Input() public mentionedUserIds: string[];
  @Input() public isAlwaysEditingMode = false;
  @Input() public emptyText = '';

  @Output() public mentionedUserIds$ = new EventEmitter<string[]>();
  @Output() public editing$ = new EventEmitter<boolean>();

  public editorView: EditorView;
  public selectedSuggestion: any;
  public suggestions: any = [];
  public suggestionsLoading$ = new BehaviorSubject<boolean>(true);
  public autocompleteValue$ = new Subject<string>();
  public popupId: string;
  public content = '';
  public contentLength: number;
  public readonly = signal<boolean>(false);
  public isFocused = signal<boolean>(false);
  public isEditButtonShown = signal<boolean>(true);
  public loadedPartly: boolean;
  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;

  private mentionIds = signal<string[]>([]);
  private range: FromTo | null;
  private nodes = basicSchema.spec.nodes.remove('image').addToEnd('mention', {
    inline: true,
    group: 'inline',
    selectable: false,
    atom: true,
    attrs: {
      id: {},
      label: {},
    },
    toDOM: (node) => [
      'span',
      { class: 'mention', 'data-id': node.attrs.id },
      node.attrs.label,
    ],
    parseDOM: [
      {
        tag: 'span.mention',
        getAttrs: (dom) => ({
          id: dom.getAttribute('data-id'),
          label: dom.textContent,
        }),
      },
    ],
  });
  private markdownParser: MarkdownParser;
  private readonly destroyRef = inject(DestroyRef);
  private onPasteLinker = new LinkyPipe();
  private currentContent = '';

  public get renderedValue(): string {
    return md.render(this.content ?? '');
  }

  constructor(
    private dataService: DataService,
    private infoPopupService: InfoPopupService,
    private scrollToService: ScrollToService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
    private injector: Injector,
    private richEditorBoxMenuService: RichEditorBoxMenuService,
  ) {
    effect(() => {
      const isReadonly = this.readonly();

      this.mentionedUserIds$.emit(this.mentionIds());
      this.editing$.emit(!isReadonly);

      if (isReadonly) {
        this.infoPopupService.close();
      }
    });
  }

  /** Gets length from content. */
  public getLengthFromContent(): number {
    return this.content?.length ?? 0;
  }

  public ngAfterViewInit(): void {
    const newSchema = new Schema({
      nodes: this.nodes,
      marks: basicSchema.spec.marks
        .append(tagMark('strike'))
        .append(tagMark('ins'))
        .append(tagMark('samp')),
    });

    defaultMarkdownSerializer.marks['em'].escape = false;
    defaultMarkdownSerializer.marks['strong'].escape = false;
    Object.keys(additionalMarks).map((mark) => {
      defaultMarkdownSerializer.marks[mark] = {
        open: additionalMarks[mark].open,
        close: additionalMarks[mark].close,
        expelEnclosingWhitespace: true,
        mixable: false,
      };
      defaultMarkdownParser.tokens[mark] = {
        mark,
      };
    });
    defaultMarkdownSerializer.nodes['mention'] = (state, node, parent) => {
      let spaceBefore = ' ';
      let spaceAfter = ' ';

      parent.descendants((n, pos) => {
        if (n.eq(node)) {
          const childBefore = parent.childBefore(pos);
          const childAfter = parent.childAfter(pos + 1);

          if (childBefore?.node?.text && childBefore.node.text.endsWith(' ')) {
            spaceBefore = '';
          }

          if (childAfter?.node?.text && childAfter.node.text.startsWith(' ')) {
            spaceAfter = '';
          }

          return false;
        }

        return true;
      });

      state.write(`${spaceBefore}${node.attrs.label}${spaceAfter}`);
    };
    defaultMarkdownParser.tokens['mention'] = {
      node: 'mention',
      getAttrs: (tok) => ({
        id: tok.attrGet('data-id'),
        label: tok.content,
      }),
    };
    defaultMarkdownSerializer.options.escapeExtraCharacters = /[+#]/g;

    const notRegisteredNodes = [
      'list_item',
      'bullet_list',
      'ordered_list',
      'image',
    ];
    notRegisteredNodes.forEach((key) => {
      delete defaultMarkdownParser.tokens[key];
    });

    this.markdownParser = new MarkdownParser(
      newSchema,
      md,
      defaultMarkdownParser.tokens,
    );

    const state = EditorState.create({
      schema: newSchema,
      plugins: [
        ...autocomplete({
          triggers: [{ name: 'mention', trigger: '@' }],
          reducer: (action) => this.handleAutocomplete(action),
        }),
        history(),
        keymap({
          'Mod-z': undo,
          'Mod-y': redo,
          'Shift-Enter': (state) => {
            const { schema, tr } = state;
            this.editorView.dispatch(
              tr.replaceSelectionWith(schema.nodes.hard_break.create()),
            );

            return true;
          },
          // Menu items hot keys.
          'Ctrl-b': () => markHotKey(this.editorView, 'strong'),
          'Ctrl-i': () => markHotKey(this.editorView, 'em'),
          'Ctrl-Shift-s': () => markHotKey(this.editorView, 'strike'),
          'Ctrl-u': () => markHotKey(this.editorView, 'ins'),
          'Ctrl-Shift-k': () => {
            this.menuBar.onItemClick(
              this.menuBar.items.find((item) => item.id === 'link'),
            );
            return false;
          },
        }),
        keymap(baseKeymap),
      ],
    });

    this.editorView = new EditorView(
      this.el.nativeElement.getElementsByClassName('editor-container')[0],
      {
        state,
        dispatchTransaction: (transaction) => {
          this.contentLength = transaction.doc.content.size - 2; // TODO: :thinking_face:
          this.cdr.markForCheck();

          if (!this.contentLength && this.currentContent) {
            transaction.storedMarks = null;
          }

          this.editorView.updateState(this.editorView.state.apply(transaction));
          this.menuBar?.toggleMenuItems();
          this.currentContent = defaultMarkdownSerializer.serialize(
            this.editorView.state.doc,
          );
          if (this.isAlwaysEditingMode) {
            this.content = this.currentContent;
            this.propagateChange(this.content);
          }
        },
        nodeViews: {
          mention: (node) =>
            new MentionView(node, this.renderer, this.mentionIds),
        },
        attributes: {
          class: 'comments-input', // TODO: make like options
        },
        transformPastedHTML: (html: string) => {
          // TODO :thinking_face:
          setTimeout(() => {
            this.makeLinksClickable();
          });

          return html;
        },
        transformPastedText: (text) => {
          this.editorView.pasteHTML(
            this.onPasteLinker.transform(md.render(text), {
              stripPrefix: false,
            }),
          );
          return '';
        },
      },
    );

    this.initSubscribers();

    if (!this.isAlwaysEditingMode) {
      this.readonly.set(true);
      this.editorView.dom.style.display = 'none';
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['mentionedUserIds']) {
      this.mentionIds.set(this.mentionedUserIds ?? []);
    }
  }

  public ngOnDestroy(): void {
    this.editorView.destroy();
  }

  public writeValue(value: any): void {
    this.content = value;
    this.contentLength = this.getLengthFromContent();
    this.currentContent = value;
    this.updateEditorContent();

    if (this.editorView?.dom && value) {
      this.makeLinksClickable();
    }

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly.set(isDisabled);
    this.isEditButtonShown.set(!isDisabled);
    /** Editor's dom shown depends on isDisabled. */
    if (this.editorView) {
      this.editorView.dom.style.display = isDisabled ? 'none' : 'block';
    }
    /** When there is an edit button, enable an editor only by click. */
    if (!this.isAlwaysEditingMode) {
      this.readonly.set(true);
      this.contentLength = this.getLengthFromContent();
      if (this.editorView) {
        this.editorView.dom.style.display = 'none';
      }
    }

    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.markForCheck();
    }
  }

  public onBlur(): void {
    this.propagateTouch();
  }

  /**
   * Inserts node with selected suggestion.
   *
   * @param suggestion user or something else.
   */
  public onSuggestionClick(suggestion: any): void {
    this.insertMention(
      `@${suggestion?.email.split('@')[0]}`,
      suggestion.id,
      this.range,
    );
    closeAutocomplete(this.editorView);
    this.editorView.focus();
  }

  /** Makes editor editable. */
  public showEditor(): void {
    this.editorView.dom.style.display = 'block';
    this.readonly.set(false);
    this.updateEditorContent();
    this.makeLinksClickable();
    this.editorView.focus();
  }

  /** Makes editor readonly. */
  public makeReadonly(): void {
    this.editorView.dom.style.display = 'none';
    this.readonly.set(true);
    this.contentLength = this.getLengthFromContent();
  }

  /** Saves editor content and propagates change. */
  public save(): void {
    this.content = this.currentContent;
    this.propagateChange(this.content);
    this.makeReadonly();
  }

  /**
   * Opens user info if mention was clicked.
   *
   * @param event Mouse event.
   */
  public openUserInfo(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!target?.classList.contains('mention')) {
      return;
    }

    this.infoPopupService.open<UserInfoComponentParams>({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          nickname: target.textContent,
        },
        injector: this.injector,
      },
    });
  }

  private openUsersPopup(): void {
    if (this.popupId) {
      this.infoPopupService.close(this.popupId);
    }

    this.popupId = this.infoPopupService.open({
      target: {
        getBoundingClientRect: () =>
          this.el.nativeElement
            .querySelector('.autocomplete')
            ?.getBoundingClientRect(),
        contextElement: this.el.nativeElement.querySelector('.ProseMirror'),
      },
      data: {
        templateRef: this.suggestionsEl,
      },
      containerStyles: {
        padding: 0,
        overflow: 'hidden',
      },
      isHideArrow: true,
      clickOutsideEnabled: false,
    });
  }

  private updateEditorContent(): void {
    this.editorView?.dispatch(
      this.editorView.state.tr.replaceWith(
        0,
        this.editorView.state.doc.content.size,
        this.markdownParser.parse(this.content ?? '').content,
      ),
    );
  }

  private scrollToSelectRow(): void {
    if (this.selectedSuggestion && this.popupId) {
      this.scrollToService.scrollTo(this.selectedSuggestion.id, 'suggestions');
    }
  }

  private insertText(text: string, range?: FromTo): void {
    const { from, to } = range ?? this.editorView.state.selection;
    this.editorView.dispatch(
      this.editorView.state.tr.deleteRange(from, to).insertText(text),
    );
  }

  private insertMention(label: string, id: string, range?: FromTo): void {
    const { from, to } = range ?? this.editorView.state.selection;

    const { schema, tr } = this.editorView.state;
    const mention = schema.nodes.mention.create({ id, label });

    this.editorView.dispatch(
      tr.deleteRange(from, to).replaceSelectionWith(mention),
    );
  }

  /**
   * Autocomplete plugin handler.
   *
   * @param action AutocompleteAction.
   * @returns `boolean` or `KEEP_OPEN` - to keep the suggestion open after selecting.
   */
  private handleAutocomplete(
    action: AutocompleteAction,
  ): boolean | typeof KEEP_OPEN {
    switch (action.kind) {
      case ActionKind.open:
        this.range = action.range;
        this.autocompleteValue$.next('');
        this.openUsersPopup();

        return true;
      case ActionKind.up: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex - 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.down: {
        const currentIndex = !this.selectedSuggestion
          ? 0
          : this.suggestions.findIndex(
              (el) => el.id === this.selectedSuggestion.id,
            );

        if (currentIndex !== this.suggestions.length - 1) {
          this.scrollToSelectRow();
          this.selectedSuggestion = this.suggestions[currentIndex + 1];
          this.cdr.markForCheck();
        }

        return true;
      }
      case ActionKind.filter:
        this.range = action.range;
        this.autocompleteValue$.next(action.filter);

        return KEEP_OPEN;
      case ActionKind.enter: {
        this.insertMention(
          `@${this.selectedSuggestion?.email.split('@')[0]}`,
          this.selectedSuggestion?.id,
          action.range,
        );

        return true;
      }
      case ActionKind.close:
        this.infoPopupService.close(this.popupId);

        return true;
      default:
        return false;
    }
  }

  private initSubscribers(): void {
    fromEvent(this.editorView.dom, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isFocused.set(true));

    fromEvent(this.editorView.dom, 'blur')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.isFocused.set(false));

    this.autocompleteValue$
      .pipe(
        tap(() => {
          this.suggestionsLoading$.next(true);
        }),
        debounceTime(Constants.textInputClientDebounce),
        switchMap((search) => {
          this.suggestionsLoading$.next(true);

          const dataParams: any = {
            top: this.loadLimit,
            select: ['id', 'name', 'email'],
            filter: [{ isActive: true }],
            orderBy: 'name',
          };

          if (search.trim()) {
            dataParams.filter.push({
              or: [
                {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'tolower(name)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
                {
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  'tolower(email)': {
                    contains: search.trim().toLowerCase(),
                  },
                },
              ],
            });
          }

          return this.dataService.collection('Users').query<User[]>(dataParams);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => {
        this.loadedPartly = data.length && data.length === this.loadLimit;

        this.suggestions = data;
        this.selectedSuggestion = this.suggestions[0];

        this.suggestionsLoading$.next(false);
      });
  }

  /** Adds click event listener to editor doc's links. */
  private makeLinksClickable(): void {
    this.editorView.dom.querySelectorAll('a').forEach((link) => {
      this.richEditorBoxMenuService.linkClickEventSubscriber(link);
    });
  }
}

// TODO: check interface NodeView for more
class MentionView {
  public dom: HTMLElement;

  private clickListener: () => void;

  constructor(
    private node: Node,
    private renderer: Renderer2,
    private mentionIds: WritableSignal<string[]>,
  ) {
    this.dom = renderer.createElement('span');
    this.dom.textContent = node.attrs.label;
    renderer.addClass(this.dom, 'mention');

    if (node.attrs.id) {
      setTimeout(() => {
        mentionIds.update((mentions) => _.uniq(mentions.concat(node.attrs.id)));
      });
    }

    this.clickListener = renderer.listen(this.dom, 'click', () => {
      console.log(node.attrs);
    });
  }

  public stopEvent(event: any): boolean {
    return event.type === 'click';
  }

  public destroy(): void {
    if (this.node.attrs.id) {
      this.mentionIds.update((mentions) =>
        mentions.filter((id) => id !== this.node.attrs.id),
      );
    }

    this.clickListener();
  }
}
