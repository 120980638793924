export interface EntityTypeDescription {
  entityTypeName: string;
  collection: string;
}

export const ENTITY_TYPE_TO_COLLECTION: ReadonlyMap<string, string> = new Map([
  ['ResourceRequestState', 'ResourceRequestStates'],
  ['Organization', 'Organizations'],
  ['Resource', 'Resources'],
  ['Department', 'Departments'],
  ['User', 'Users'],
  ['Project', 'Projects'],
  ['Role', 'Roles'],
  ['Competence', 'Competences'],
  ['Activity', 'Activities'],
  ['ProjectBillingType', 'ProjectBillingTypes'],
  ['ProjectState', 'ProjectStates'],
  ['ProjectTask', 'ProjectTasks'],
  ['TimeOffType', 'TimeOffTypes'],
  ['TimeOffRequest', 'TimeOffRequests'],
  ['ExpenseRequest', 'ExpenseRequests'],
  ['TimeSheet', 'TimeSheets'],
  ['Invoice', 'Invoices'],
  ['Program', 'Programs'],
  ['Skill', 'Skills'],
  ['ResourcePool', 'ResourcePools'],
  ['FinanceReportLineType', 'FinanceReportLineTypes'],
  ['State', 'States'],
  ['ActOfAcceptance', 'ActsOfAcceptance'],
  ['ProjectVersion', 'ProjectVersions'],
  ['Currency', 'Currencies'],
  ['FinancialAccount', 'FinancialAccounts'],
  ['Location', 'Locations'],
  ['Grade', 'Grades'],
  ['Level', 'Levels'],
  ['LegalEntity', 'LegalEntities'],
  ['ProjectCostCenter', 'ProjectCostCenters'],
  ['Issue', 'Issues'],
  ['IssueType', 'IssueTypes'],
]);
