import { Injectable } from '@angular/core';

import {
  NgbModal,
  NgbOffcanvas,
  NgbOffcanvasOptions,
  NgbOffcanvasRef,
} from '@ng-bootstrap/ng-bootstrap';
import { TransitionService } from '@uirouter/core';

import { BehaviorSubject, Subject } from 'rxjs';

import {
  OffCanvasActionPanelService,
  OffCanvasComponent,
} from 'src/app/shared/components/features/off-canvas/off-canvas.component';
import {
  OffCanvasContent,
  OffCanvasEntry,
} from 'src/app/shared/models/off-canvas/off-canvas-entry.interface';

@Injectable({
  providedIn: 'root',
})
export class OffCanvasService {
  private entityUpdatedSubject = new BehaviorSubject<unknown>(null);
  public entityUpdated$ = this.entityUpdatedSubject.asObservable();
  private offCanvasWidthSubject = new BehaviorSubject<number>(null);
  public offCanvasWidth$ = this.offCanvasWidthSubject.asObservable();
  private reRenderSubject = new Subject<boolean>();
  public reRender$ = this.reRenderSubject.asObservable();

  public offCanvasEntry: OffCanvasEntry;
  public offCanvasActionPanelService: OffCanvasActionPanelService;

  private offCanvas: NgbOffcanvasRef;
  private readonly delayBeforeRender = 100;

  constructor(
    private bootstrapOffCanvasService: NgbOffcanvas,
    private ngbModal: NgbModal,
    transitionService: TransitionService,
  ) {
    transitionService.onSuccess({}, () => {
      this.closeOffCanvas();
    });
  }

  /**
   * Inits entry and opens off canvas.
   *
   * @param entityId id of needed entity.
   * @param entry off canvas content config.
   * @param animation off canvas animation property.
   */

  public openOffCanvas<T>(
    entry: OffCanvasEntry<T>,
    options?: NgbOffcanvasOptions,
  ): void {
    this.offCanvasEntry = entry;

    const defaultOptions: NgbOffcanvasOptions = {
      position: 'end',
      backdrop: false,
      panelClass: 'active-task-panel',
      scroll: true,
      animation: true,
    };

    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        defaultOptions[key] = value;
      });
    }

    this.offCanvas = this.bootstrapOffCanvasService.open(
      OffCanvasComponent,
      defaultOptions,
    );
  }

  /**
   * Changes current content inside to the new one.
   *
   * @param content Component or template reference.
   */
  public reRenderContent<T>(content: OffCanvasContent<T>): void {
    this.offCanvasEntry.onClose ??= () => Promise.resolve();

    this.offCanvasEntry.onClose().then(
      () => {
        this.reRenderSubject.next(true);
        this.offCanvasEntry.content = content;

        setTimeout(() => {
          this.reRenderSubject.next(false);
        }, this.delayBeforeRender);
      },
      () => null,
    );
  }

  /**
   * Closes offCanvas and clears its entry.
   * Also waits for the result before closing if the entry has an `onClose` callback.
   */
  public async closeOffCanvas(): Promise<void> {
    if (!this.offCanvas || this.ngbModal.hasOpenModals()) {
      return;
    }

    if (!this.offCanvasEntry?.onClose) {
      this.dismiss();
      return;
    }

    this.offCanvasEntry.onClose().then(
      () => this.dismiss(),
      () => null,
    );
  }

  /**
   * Emits event on entity update.
   *
   * @param entity updated entity.
   */
  public onEntityUpdated(entity: unknown): void {
    this.entityUpdatedSubject.next(entity);
  }

  /**
   * Sets offCanvas wrapper width.
   *
   * @param width current width.
   */
  public setWidth(width: number): void {
    this.offCanvasWidthSubject.next(width);
  }

  /**
   * Checks is the component of offCanvas entry an instance of the component.
   *
   * @param component Any component.
   * @returns `true` if it is, otherwise `false`.
   */
  public isEntryEqualComponent(component: object): boolean {
    return !!(
      this.offCanvasEntry?.content.component &&
      component instanceof this.offCanvasEntry.content.component
    );
  }

  private dismiss(): void {
    this.offCanvas.dismiss('close');
    this.offCanvasEntry = null;
  }
}
